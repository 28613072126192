
body, html {
  overflow-x: hidden;
}

  .contact-info h4 {
    color: black !important;
    overflow:0 !important;
  }
  
  .contact-info p {
    margin: 0.5rem 0 !important;
    color: black !important;
  }
  
  .contact-info a {
    color: #0d6efd !important;
    text-decoration: none !important;
  }
  
  .social-media-links i {
    color: #0d6efd;
    margin: 0 10px !important;
  }
  
  .social-media-links i:hover {
    color: #0056b3;
  }
  
  #cts{
    font-size: 20px !important;
  }
  #eml{
    font-size: 20px !important;
  }
  #phn{
    font-size: 20px !important;
  }


  @media(max-width:768px){
    .contact-info h4{
      margin-top: 1rem !important;
    }
  }