/* Navbar container */
.navbar {
    background-color: aliceblue !important;
    z-index: 1000;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Navbar brand logo */
.navbar-brand img {
    height: 40px;
    width: auto;
    position: relative;
}

/* Styling for each nav-item */
.nav-item {
    color: black !important;
    font-size: 18px !important;
    font-weight: 500; /* Slightly lighter weight for a professional look */
    margin-right: 1.5rem !important;
    position: relative;
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif;  /* Professional font */
    letter-spacing: 0.5px;  /* Small letter spacing for clean look */
}

/* Hover effect for nav-item */
.nav-item:hover {
    color: #ffd700; /* Golden color for hover effect */
}

/* Icon effect */
.nav-item i {
    transition: color 0.3s ease, transform 0.2s ease;
}

/* Hover zoom effect for icons */
.nav-item:hover i {
    transform: scale(1.2); /* Slight zoom effect on the icon */
}

/* Active nav-item style */
.nav-item.active {
    color: #ffc107;
    font-weight: 700;
    text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.5);
}

/* Underline effect when hovering or active */
.nav-item::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 0;
    height: 2px;
    background: #ffc107;
    transition: width 0.3s ease, left 0.3s ease;
}

.nav-item:hover::after {
    width: 100%;
    left: 0;
}

.nav-item.active::after {
    width: 100%;
    left: 0;
}

/* Navbar toggle button styling */
.custom-toggle {
    border: none;
    background-color: rgb(255, 255, 255) !important;
    margin-top: -7px;
    height: 50px !important;
}

/* Page content styling */
.page-content {
    padding-top: 80px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .navbar {
        min-height: 60px; /* Adjust for smaller screens */
    }

    .navbar-brand img {
        height: 25px !important; /* Slightly smaller for mobile */
        margin-top: 1rem !important; /* Adjust margin */
    }

    .navbar .nav-item {
        font-size: 20px !important; /* Increase font size for mobile */
    }
}
