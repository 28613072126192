.our-clients-section {
    padding: 3rem 1rem;
    background-color: #f9f9f9; /* Light background for contrast */
  }
  
  .client-section-heading {
    font-size: 2.5rem !important;
    color: black;
    font-weight: bold;
    margin-top: -2rem !important;
  }
  
  .client-card {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    transition: transform 0.3s ease;
  }
  
  .client-card:hover {
    transform: scale(1.05);
  }
  
  .client-logo {
    height: 120px; /* Fixed height for logos */
    width: auto;
    object-fit: contain;
    margin: 1rem auto;
  }
  
  .client-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #555;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .client-card {
      margin: 0 auto;
    }
    .client-section-heading {
      font-size: 2rem !important;
      color: black;
    }
  
  }
  
  @media (max-width: 576px) {
    .client-section-heading {
      font-size: 2rem !important;
      color: black;
    }
  
    .client-name {
      font-size: 1rem;
    }
  }
  