/* Container Styling */
.unique-homepage-container {
  background-color: #f8f9fa; /* Light gray background */
}

/* Image Section */
.unique-image-section {
  text-align: center;
}

.unique-main-image {
  max-height: 330px !important;
  object-fit: cover;
  width: 95% !important;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-left: 1rem !important;
}

/* Text Section */
.unique-text-section {
  color: black !important; /* Dark text color */
}

.unique-heading {
  font-size: 2.5rem;
  color: black !important;
  text-align: center;
  margin-top: 1rem !important;
  position: absolute !important;
}

.unique-subheading {
  font-size: 1.2rem;
  color: black !important;
  text-align: center;
  margin-top: 5rem !important;
}

/* Info Section */
.unique-info-section {
  margin-top: 20px;
 
}

.unique-info-box {
  text-align: left;
}

.unique-info-number {
  font-size: 1.9rem;
  font-weight: bold;
  color: #ffb619 !important;
  text-align: center !important; /* Accent color */
}

.unique-info-text {
  font-size: 1.2rem !important;
  color: black !important;
  text-align: center !important;
}

/* Button Styling */
.unique-contact-button {
  background-color: #ff7b00;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
}

.unique-contact-button:hover {
  background-color: #e66a00;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .unique-text-section {
    margin-top: 2rem;
    text-align: center;
  }
  .unique-heading{
    font-size: 2rem !important;
    margin-left: 1rem !important;

  }

  .unique-info-section {
    text-align: center;
  }

  .unique-info-box {
    margin-bottom: 20px;
  }
  .unique-main-image{
    
    margin-left: 0.6rem !important;
  }
}
