body {
    font-family: 'Roboto', sans-serif !important; /* Example: Use Roboto */
  }
  
  
  
  /* Container Styling */
  .schools-excellence-container {
    padding: 3rem 1rem;
  }
  
  /* Image Column Styling */
  .schools-excellence-image-column {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .schools-excellence-image {
    border-radius: 10px;
    width: 100%;
    height: 21rem !important;
  }
  
  /* Text Column Styling */
  .schools-excellence-text-column {
    text-align: left;
  }
  
  .schools-excellence-heading {
    color: black !important;
    font-size: 2.5rem !important;
    margin-bottom: 1.5rem;
  }
  
  .schools-excellence-paragraph {
    font-size: 1.3rem;
    line-height: 1.5;
    color: black !important;
    margin-top: -5rem !important;
  }
  
  .schools-excellence-button {
    display: block;
    margin: 1.5rem auto;
    font-size: 1.2rem;
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 5px;
  }
  
  /* Responsive Styles for Mobile View */
  @media (max-width: 768px) {
    .schools-excellence-row {
      flex-direction: column; /* Stack the items */
    }
  
    .schools-excellence-heading-column {
      order: 1; /* Heading first */
      text-align: center;
  
    }
  
    .schools-excellence-image-column {
      order: 2; /* Image second */
    }
  
    .schools-excellence-text-column {
      order: 3; /* Text third */
      text-align: center;
      margin-top: 1rem;
    }
  
    .schools-excellence-button-column {
      order: 4; /* Button last */
      text-align: center;
    }
  
    .schools-excellence-heading {
      font-size: 2rem !important;
    }
  
    .schools-excellence-paragraph {
      font-size: 1.2rem;
      margin-top: -5px !important;
      text-align: left !important;
    }
    .schools-excellence-container{
      margin-top: -2rem !important;
    }
    .schools-excellence-image{
      height: 13rem !important;
    }
  }
  