/* Container Styles */
.h-my-4 {
  margin-top: 2rem !important;
}

/* Column Padding */
.service-col {
  padding-left: 15px;
  padding-right: 15px;
}

/* Card Styling */
.card-icon {
  display: block; /* Ensures the icon is treated as a block */
  margin: 0 auto; /* Centers the icon horizontally */
  max-width: 100%; /* Ensures responsiveness */
  height: auto; /* Maintains aspect ratio */
  width: 50px; /* Default width for larger screens */
  height: 50px; /* Default height for larger screens */
}

.text-muted {
  color: black !important;
  text-align: left !important;
}

.fw-bold {
  font-size: 1.4rem !important;
}

/* Mobile Specific Styles */
@media (max-width: 576px) {
  .se-fw-bold {
    font-size: 2rem !important;
    font-weight: bold !important;
  }

  /* Center text and icon for mobile */
  .card {
    text-align: center;
  }

  .card-icon {
    width: 40px; /* Reduce icon size for mobile */
    height: 40px;
  }

  /* Adjust text spacing */
  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 1.2rem;
  }

  .h-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* Tablet Specific Styles */
@media (min-width: 577px) and (max-width: 768px) {
  h1 {
    font-size: 1.6rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .card-text {
    font-size: 1.2rem !important;
  }

  .card-icon {
    width: 45px; /* Adjust icon size for tablets */
    height: 45px;
  }
 
}
