.whatsapp-icon {
    position: fixed;
    bottom: 20px; /* Distance from the bottom of the screen */
    right: 20px; /* Distance from the right of the screen */
    background-color: #25d366; /* WhatsApp green */
    color: white;
    font-size: 1.8rem; /* Size of the icon */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000; /* Ensures it stays above most elements */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .whatsapp-icon:hover {
    transform: scale(1.1); /* Slightly enlarges the icon on hover */
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.4);
  }
  