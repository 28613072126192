.ourstrength{
    text-align: center !important;
}
body, html {
  overflow-x: hidden;
}


.icon {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1rem;
    margin-left: 10rem;
    position: absolute;
  }
 
  
  .strength-fw-bold{
    font-size: 2.5rem !important;
    font-weight: bold !important;
  }
  
  @media (max-width: 768px) {
    .icon {
      font-size: 1.2rem;
    }
    .strength-fw-bold{
        font-size: 2rem !important;
      }
      .text-center{
        margin-top: -0.5rem !important;
      }
  }
  

  @media (max-width: 576px){
    .strength{
        font-size: 2rem !important;
      }
  } 


