/* General Styling */
.content1-section {
  margin-top: 5rem;
  background: #f8f9fa;
  padding: 3rem 0; /* Reduced padding at the top and bottom */
  border-top: 5px solid #ffb619;
  border-bottom: 5px solid #ffb619;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5rem; /* Slightly reduced font size */
  line-height: 1.4;
  color: #333;
  margin-bottom: 1.2rem; /* Reduced bottom margin for closer spacing */
}

.highlighted-text {
  color: #ffb619;
  font-style: italic;
}

.description-text {
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem; /* Reduced font size slightly */
  line-height: 1.7;
  color: #555;
  margin: 0 auto;
  max-width: 700px; /* Reduced max width */
}

.cta-button {
  background-color: #ffb619;
  color: #fff;
  border: none;
  padding: 0.7rem 1.8rem; /* Slightly smaller padding */
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #e6a814;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
