.latest-custom-card {
    height: 100%; /* Ensures all cards have equal height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border:  2px solid 	#F5F5F5;
  }
 
  
  .latest-custom-image {
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* Ensures images maintain aspect ratio and fill the space */
  }
  .mb-4{
    font-size: 2.5rem !important;
  }



  @media (max-width: 576px) {
    .mb-4 {
      font-size: 2rem !important;
      color: black;
    }
  }