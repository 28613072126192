.about-us-section {
  padding: 1rem 1rem; /* Add more padding for a spacious look */
  background-color: #f8f9fa; /* Light background for contrast */
  overflow: hidden; 
  margin-top: -50px !important;

  /* Prevent overflow */

}
@media (max-width: 768px) {
  .about-us-heading {
    font-size: 2rem !important; /* Smaller font size for mobile */
  }
}

.about-us-heading {
  text-align: center; /* Center the heading */
  margin-bottom: 3rem; /* Space between heading and content */
  font-size: 2.5rem !important; /* Adjust font size as needed */
  font-weight: bold; /* Make the heading bold */
  color: black !important; /* Darker color for text */
}

.about-card {
  border-radius: 10px; /* Rounded corners for the card */
  overflow: hidden; /* Ensure rounded corners are effective */
  
}

.about-image {
  width: 100%; /* Make the image responsive */
  height: 78%; /* Maintain aspect ratio */
  border-top-left-radius: 10px; /* Rounded corners for the left side */
  border-top-right-radius: 10px; /* Rounded corners for the right side */
  margin-top: 1.5rem !important;
  margin-left: 2px !important;
}

.about-text-col {
  padding: 2rem; /* Add padding for text */
  text-align: left; 
  margin-top: -1.4rem !important;/* Align text to the left */
}

.about-card .card-text {
  font-size: 1.2rem; /* Adjust text size for readability */
  line-height: 1.5; /* Increase line height for better readability */
}

.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Ensure the Row and Col do not exceed their parent's width */
.row {
  margin-left: 0;
  margin-right: 0;
}

.col, .col-6, .col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.custom-card-height {
  height: auto !important; /* Automatically adjust height to content */
  width: 100% !important; /* Adjust width for mobile */
  margin-left: 0 !important; /* Remove left margin for mobile view */
  margin-top: 0 !important; /* Ensure no margin from top */
  padding: 0 !important; /* Remove extra padding */
}

@media (max-width: 768px) {
  .about-image {
    height: auto !important; /* Allow the image to adjust its height */
    margin-top: 0 !important; /* Remove extra margin */
  }

  .about-text-col {
    padding: 1rem !important; /* Reduce padding for mobile */
  }

  .about-card {
    border-radius: 5px; /* Slightly smaller radius for smaller screens */
  }

  .about-us-section {
    padding: 1rem 0 !important; /* Reduce padding for mobile view */
  }

  .about-us-heading {
    font-size: 2rem !important; /* Adjust heading font size for mobile */
  }
}
